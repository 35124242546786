/** @format */

import ActionTable from "components/Table/ActionTable";
import { useState, useEffect, useRef } from "react";
import { useStyles } from "./FirstLoginReportStyles";
import {
  DEFAULT_PER_PAGE_NUM,
  FILTER_MODE_CONTAINS,
  FILTER_MODE_ENDS_WITH,
  FILTER_MODE_STARTS_WITH,
  USERS_AUTHORITIES_CLIENT_GUEST,
} from "config/constant";

import { defaultFilterParameters } from "components/Table/TableBase/TableFilter";
import { CSVLink } from "react-csv";

import UsersTableContent from "./Components/FirstLoginReportContent/UsersTableContent";
import { ApiUsers } from "Apis/ApiUsers";
import { ProcUsers } from "Utilities/ProcUsers";

import DownloadIcon from "@material-ui/icons/GetApp";
import MailIcon from "@material-ui/icons/Mail";
import { getClinetId } from "Utilities/appHelper";
import { ApiClientsId } from "Apis/ApiClientsId";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import MessageModal from "components/MessageModal/MessageModal";
import { ApiUsersInvitation } from "Apis/ApiUsersInvitation";
import { AfterApiIsLogin } from "Utilities/AuthUtility";

export const tableHeaderUsers = (
  authType: "employee_id" | "password" | null
) => [
  "No",
  "氏名",
  authType == "password" ? "メールアドレス" : "従業員番号",
  authType == "password" ? "再招待メール" : "",
];

const filterColumnsUsers = (authType: "employee_id" | "password" | null) => [
  authType === "password" ? "メールアドレス" : "従業員番号",
  "氏名",
];

export const FirstLoginReport = () => {
  const classes = useStyles();

  const defaultSortHeader = 0;
  const defaultReverseFlg = false;
  const [sortHeader, setSortHeader] = useState(defaultSortHeader);
  const [reverseFlg, setReverseFlg] = useState(defaultReverseFlg);
  const [filterParameters, setFilterParameters] = useState<any>(
    defaultFilterParameters
  );

  const [allUsersArray, setAllUsersArray] = useState<Array<any>>([]);
  const [unloggedUsersArray, setUnloggedUsersArray] = useState<Array<any>>([]);
  const [unloggedUsersArrayTmp, setUnloggedUsersArrayTmp] = useState<
    Array<any>
  >([]);
  const [outputCsvFetchDone, setOutputCsvFetchDone] = useState(false);
  const [csvOutputHeader, setCsvOutputHeader] = useState<Array<any>>([]);
  const [csvOutputdata, setCsvOutputdata] = useState<Array<any>>([]);
  const [authType, setAuthType] = useState<"password" | "employee_id" | null>(
    null
  );
  const [errMsg, setErrMsg] = useState("");

  const [actionUser, setActionUser] = useState<any>(null);
  const [showReinvitationModal, setShowReinvitationModal] = useState(false);
  const [errorMessageModalStatus, setErrorMessageModalStatus] = useState(false);
  const [showAfterModal, setShowAfterModal] = useState(false);

  const outputCsvFetchDoneRef = useRef<any>();

  useEffect(() => {
    (async () => {
      const clientId = getClinetId();
      if (!clientId) return;
      const response = await ApiClientsId(clientId);
      setAuthType(response["result-object"].authentication_type);
      ApiUsers(callBackApiUsers(ProcUsers));
    })();
  }, []);

  useEffect(() => {
    handleFilter(filterParameters, false);
  }, [unloggedUsersArray]);

  useEffect(() => {
    if (outputCsvFetchDone) {
      outputCsvFetchDoneRef.current.link.click();
      setOutputCsvFetchDone(false);
    }
  }, [outputCsvFetchDone]);

  function callBackApiUsers(func: any) {
    var list: any = [];
    return (allUsers: any) => {
      allUsers.sort(compare_no);
      allUsers.reverse();
      // MEMO: guestは非表示
      const filteredUsers = allUsers.filter(
        (item: any) =>
          !item.authorities.includes(USERS_AUTHORITIES_CLIENT_GUEST)
      );
      setAllUsersArray(filteredUsers);
      func(filteredUsers).forEach((item: any) => {
        if (
          item.done_first_login !== true &&
          item.done_first_login !== "true"
        ) {
          item.no = list.length + 1;
          list.push(item);
        }
      });
      setUnloggedUsersArray(list);
    };
  }

  //--------------display table change----------

  //-----------pagination function start------------------------
  const defaultCurrentPage = 0;
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_NUM);
  const handlePgNum = (pgNum: number) => {
    setCurrentPage(pgNum);
  };
  const handlePgRows = (rows: number) => {
    setPerPage(rows);
    setCurrentPage(0);
  };

  //-------------header sort function----------
  const handleHeader = (e: any) => {
    var workReverseFlg = false;
    if (e === sortHeader) {
      workReverseFlg = !reverseFlg;
    }
    setReverseFlg(workReverseFlg);
    setSortHeader(e);

    var list: any = [];

    unloggedUsersArray.forEach((item: any) => {
      list.push(item);
    });

    switch (e) {
      case 0:
        list.sort(compare_no);
        break;
      case 1:
        list.sort(compare_full_name_display);
        break;
      case 2:
        list.sort(compare_business_email_display);
        break;
      default:
    }

    if (workReverseFlg) {
      list.reverse();
    }

    setUnloggedUsersArray(list);
  };

  function compare_no(a: any, b: any) {
    if (a.no < b.no) {
      return -1;
    }
    if (a.no > b.no) {
      return 1;
    }
    return 0;
  }

  function compare_full_name_display(a: any, b: any) {
    if (a.full_name < b.full_name) {
      return -1;
    }
    if (a.full_name > b.full_name) {
      return 1;
    }
    return 0;
  }

  function compare_business_email_display(a: any, b: any) {
    if (a.business_email < b.business_email) {
      return -1;
    }
    if (a.business_email > b.business_email) {
      return 1;
    }
    return 0;
  }

  function compare_phone_display(a: any, b: any) {
    if (a.phone < b.phone) {
      return -1;
    }
    if (a.phone > b.phone) {
      return 1;
    }
    return 0;
  }

  //-------------filter function----------
  const handleFilter = (
    filterParameters: any,
    filterChangeTrigger: boolean
  ) => {
    if (unloggedUsersArray?.length > 0) {
      var list: any = [];
      var flg = false;

      unloggedUsersArray.forEach((item: any) => {
        flg = false;
        if (filterParameters.filterText === "") {
          flg = true;
        } else {
          switch (filterParameters.filterColumn) {
            case 0:
              flg = filterMatchCheck(
                item.business_email,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            case 1:
              flg = filterMatchCheck(
                item.full_name,
                filterParameters.filterText,
                filterParameters.filterMode
              );
              break;
            default:
          }
        }

        if (flg) {
          list.push(item);
        }
      });

      setUnloggedUsersArrayTmp(list);
    }

    if (filterChangeTrigger) {
      setFilterParameters({
        ...filterParameters,
        filterColumn: filterParameters.filterColumn,
        filterMode: filterParameters.filterMode,
        filterText: filterParameters.filterText,
      });
    }
  };

  function filterMatchCheck(
    targetStr: string,
    searchStr: string,
    checkType: number
  ) {
    targetStr = targetStr?.toLowerCase();
    searchStr = searchStr?.toLowerCase();
    var result = false;

    switch (checkType) {
      case FILTER_MODE_CONTAINS:
        result = targetStr?.indexOf(searchStr) >= 0;
        break;
      case FILTER_MODE_STARTS_WITH:
        result = targetStr?.startsWith(searchStr);
        break;
      case FILTER_MODE_ENDS_WITH:
        result = targetStr?.endsWith(searchStr);
        break;
      default:
    }

    return result;
  }

  function handleOutputCsv() {
    setCsvOutputHeader([
      { label: "ユーザー氏名", key: "full_name" },
      {
        label: authType == "password" ? "メールアドレス" : "従業員番号",
        key: "business_email",
      },
    ]);
    setCsvOutputdata(unloggedUsersArrayTmp);
    setOutputCsvFetchDone(true);
  }

  const sendInvitationMailToAll = async () => {
    if (
      window.confirm(
        `初回ログインが完了していない方全員に再招待メールを送りますか？`
      )
    ) {
      await Promise.all(
        unloggedUsersArray.map((user) => handleReInvitation(user))
      );
    }
  };

  const handleConfirmModal = (user: any) => {
    setActionUser(user);
    setShowReinvitationModal(true);
  };
  const handleReInvitation = async (actionUser: any) => {
    var obj = { id: actionUser.id };
    var response: any = await ApiUsersInvitation(obj);
    loginCheck(response);
    if (response["result-status"] === 200) {
      actionUser.enable = !actionUser.enable;
      actionUser = {};
      setShowReinvitationModal(false);
      setShowAfterModal(true);
    } else {
      setErrMsg(
        "ApiUsersUpdate\nresult-status = " +
          response["result-status"] +
          "\nmessage = " +
          response.message
      );
      setErrorMessageModalStatus(true);
    }
  };
  function loginCheck(data: any) {
    AfterApiIsLogin(data["result-status"]);
  }
  const handleErrorMessageModal = () => {
    setErrorMessageModalStatus(false);
  };
  const handleReinvitationConfirmClose = () => {
    setShowReinvitationModal(false);
  };

  const handleCloseAfterModal = () => {
    setShowAfterModal(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <div>
            <div className={classes.reportTitle}>初回ログイン未完了者一覧</div>
            <div className={classes.reportSmallTitle}>
              以下は、初回ログインを完了できていない方です。
            </div>
          </div>
          <div>
            <div className={classes.loginRateContainer}>
              <div className={classes.loginRateTitle}>初回ログイン完了率</div>
              <div>
                <span className={classes.loginRateLarge}>
                  {allUsersArray?.length - unloggedUsersArray?.length}{" "}
                </span>
                <span className={classes.loginRateSmall}>
                  / {allUsersArray?.length}名
                </span>
              </div>
            </div>
            <div className={classes.menuContainer}>
              {authType == "password" && (
                <div
                  className={classes.outputCsv}
                  onClick={sendInvitationMailToAll}
                >
                  <MailIcon fontSize="small" />
                  再招待メールを一斉送信
                  {outputCsvFetchDone && (
                    <CSVLink
                      data={csvOutputdata}
                      headers={csvOutputHeader}
                      filename={"output.csv"}
                      ref={outputCsvFetchDoneRef}
                    />
                  )}
                </div>
              )}
              <br />
              <div className={classes.outputCsv} onClick={handleOutputCsv}>
                <DownloadIcon fontSize="small" />
                CSV出力
                {outputCsvFetchDone && (
                  <CSVLink
                    data={csvOutputdata}
                    headers={csvOutputHeader}
                    filename={"output.csv"}
                    ref={outputCsvFetchDoneRef}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ActionTable
          className={classes.tableRoot}
          handlePgNum={handlePgNum}
          handlePgRows={handlePgRows}
          totalCnt={unloggedUsersArrayTmp?.length}
          PgNum={currentPage}
          PgRows={perPage}
          maxPgRows={unloggedUsersArrayTmp?.length}
          filterColumns={filterColumnsUsers(authType)}
          changeFilterParameters={(e) => handleFilter(e, true)}
          filterParameters={filterParameters}
          tableContent={
            <UsersTableContent
              onRow={(e) => {}}
              rows={unloggedUsersArrayTmp}
              pageNumber={currentPage}
              perPageNumber={perPage}
              columns={tableHeaderUsers(authType)}
              sortHeader={sortHeader}
              reverseFlg={reverseFlg}
              headerSort={(e) => handleHeader(e)}
              authType={authType}
              handleConfirmModal={handleConfirmModal}
            />
          }
        />
        <ConfirmModal
          title="招待メールを送信"
          btitle="送信"
          description={`「${actionUser?.full_name}」に招待メールを送りますか`}
          show={showReinvitationModal}
          onClose={handleReinvitationConfirmClose}
          action={() => handleReInvitation(actionUser)}
        />
        <MessageModal
          title="エラー"
          description={`エラーが発生しました。\n\n<<エラーメッセージ>>\n${errMsg}`}
          show={errorMessageModalStatus}
          onClose={() => setErrorMessageModalStatus(false)}
          action={handleErrorMessageModal}
        />
        <MessageModal
          title="送信完了"
          description="メールの送信が完了しました"
          show={showAfterModal}
          onClose={handleCloseAfterModal}
          action={handleCloseAfterModal}
        />
      </div>
    </div>
  );
};
